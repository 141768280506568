.DownloadNavbar-container-65 {
  margin: 0 !important;
  width: 100% !important;
  padding: 0 !important;
  min-height: 35px !important;
}

.DownloadNavbar-flex-66 {
  display: none !important;
  color: red !important;
}

.MuiToolbar-regular {
  min-height: 20px;
}

.display-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: orange;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: blue;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid orange;
  /* creates padding around scroll thumb */
}
