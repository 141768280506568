.pieEmpty {
  path {
    fill: #cccccc !important;
  }
  .dxc-labels-group {
    rect {
      fill: #cccccc !important;
    }
  }

  .dxl-marker {
    rect {
      fill: #cccccc !important;
    }
  }
}
