.container {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;
  margin: auto;
  width: 100%;
  z-index: 100;
  padding: 0;
  margin: 0;
}

.emailInputContainer {
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 85%;
  border: solid 1px grey;
  background-color: white;
}

.emailInput {
  border: solid 0px;
  height: 35px;
}

.emailButton {
  display: flex;
  align-items: center;
  background-color: #333333;
  border: none;
  color: white;
  height: 35px;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
}

.emailButton:hover {
  background-color: #ff8d18;
}

.buttonText {
  color: white;
}

.c {
  padding: 10px;
  display: grid;
  width: 60%;
  height: 400px;
  grid-template-columns: auto auto;
  grid-template-rows: (4, 1fr);

  background-color: #cbcbcb;
}
#img {
  width: 80%;
  height: 100%;
}
.image {
  grid-row: 1/6;
  grid-column: 1/2;
}
.title {
  font-size: 18px;
  margin-top: 30px;
  text-align: justify;
  color: #ff9800;
  grid-row: 1/2;
  grid-column: 2/3;
}
.text {
  text-align: justify;
  width: 45%;
  padding: 10px 0px;
  font-weight: bold;
  grid-row: 2/3;
  grid-column: 2/3;
}
.email {
  grid-row: 3/4;
  grid-column: 2/3;
}
.buttons {
  padding: 40px 0;
  display: flex;
  width: 86%;
  justify-content: space-between;
  grid-row: 4/5;
  grid-column: 2/3;
}
.buttons img {
  cursor: pointer;
}
.buttons img:nth-of-type(1) {
  width: 150px !important;
}
.buttons img:nth-of-type(2) {
  width: 150px !important;
  height: 50px !important;
}
.page {
  align-items: center;
}
@media screen and (max-width: 768px) {
  .c {
    grid-template-columns: auto auto;
    grid-template-rows: (4, 1fr);
    width: 100%;
    padding-left: 20px;
    height: 500px;
  }
  .page {
    border: 2px solid;
    padding-top: 90px;
    align-items: unset !important;
  }
  .emailInputContainer {
    width: 100% !important;
  }

  .emailButton {
    text-align: unset !important;
    font-size: 13px;
  }
  .title {
    font-size: 15px;
    margin-top: 0 !important;
    text-align: center !important;
    color: #ff9800;
    grid-row: 1/2;
    grid-column: 1/3;
  }
  .title h3 {
    position: relative;
  }
  .title h3::after {
    content: '';
    position: absolute;
    border-top: 1px solid;
    left: 35%;
    bottom: 0;
    width: 30%;
    height: 0px;
  }
  .image {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  .text {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  .email {
    grid-row: 3/4;
    grid-column: 1/3;
  }
  .buttons {
    width: 100%;
    padding: 0;
    grid-row: 4/5;
    grid-column: 1/3;
  }
}

@media screen and (max-width: 600px), screen and (min-width: 600px) {
  .buttons {
    padding: 20px 0;
  }
  .text {
    display: flex;
    align-items: center;
  }
}
